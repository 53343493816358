import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: null,
    rol: null,
    loggingIn: false,
    loginError: null
  },
  mutations: {
    loginStart: state => (state.loggingIn = true),
    loginStop: (state, errorMessage) => {
      state.loggingIn = false;
      state.loginError = errorMessage;
    },
    updateAccessToken: (state, data) => {
      if (data.token === null) {
        state.accessToken = null;
        state.rol = null;
      } else {
        state.accessToken = data.token;
        if (Array.isArray(data.roles)) {
          state.rol = data.roles[0];
        } else {
          state.rol = data.rol;
        }
      }
    },
    logout: state => {
      state.accessToken = null;
    }
  },
  actions: {
    doLogin({ commit }, loginData) {
      commit("loginStart");
      axios
        .post(
          "https://toolsusers." +
            process.env.VUE_APP_BASE_URL +
            "/api/login_check",
          loginData
        )
        .then(response => {
          console.log(response);
          localStorage.setItem("accessTokenAptentDev", response.data.token);
          localStorage.setItem("rolAptentDev", response.data.roles[0]);
          commit("loginStop", null);
          commit("updateAccessToken", response.data);
          router.push({ name: "init" });
        })
        .catch(error => {
          commit("loginStop", error.response.data.error);
          commit("updateAccessToken", null);
        });
    },
    fetchAccessToken({ commit }) {
      let data = {
        token: localStorage.getItem("accessTokenAptentDev"),
        rol: localStorage.getItem("rolAptentDev")
      };
      commit("updateAccessToken", data);
    },
    logout({ commit }) {
      localStorage.removeItem("accessTokenAptentDev");
      localStorage.removeItem("rolAptentDev");
      commit("logout");
      router.push({ name: "Home" });
    }
  }
});
